/* @flow */

import graphql from 'babel-plugin-relay/macro';
import { ConnectionHandler } from 'relay-runtime';
import pick from 'lodash/pick';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation UpdatePrimaryTagMutation($input: UpdatePrimaryTagInput!) {
    updatePrimaryTag(input: $input) {
      primaryTag {
        id
        title
        slug
        emoji
        remote
        boost
        do_not_suggest
        block
        used_at
      }
      tagsEdges {
        edges {
          node {
            id
            title
            slug
            emoji
            remote
            boost
            do_not_suggest
            block
            used_at
            # no need to fetch other tag data since update mutation won't affect it
          }
        }
      }
      error {
        message
      }
    }
  }
`;

export default (
  primaryTagInput: {
    id: string,
    title?: string,
    emoji?: string,
    remote?: boolean,
    boost?: boolean,
    do_not_suggest?: boolean,
    block?: boolean,
  },
  { primaryTag } = {},
) =>
  new Promise((resolve, reject) => {
    const { id } = primaryTagInput;
    const variables = {
      input: primaryTagInput,
    };

    commitMutation({
      mutation,
      variables,
      optimisticResponse: {
        updatePrimaryTag: {
          error: null,
          primaryTag: {
            id,
            ...pick(primaryTag || {}, [
              'title',
              'slug',
              'emoji',
              'remote',
              'boost',
              'do_not_suggest',
              'block',
            ]),
            used_at: new Date().toISOString(),
            ...primaryTagInput,
          },
        },
      },
      updater: (store) => {
        const payload = store.getRootField('updatePrimaryTag');
        const tagsEdges = payload
          .getLinkedRecord('tagsEdges')
          ?.getLinkedRecords('edges');
        const error = payload.getLinkedRecord('error');

        if (!error && tagsEdges?.length) {
          // add tag to TagsViewCurate_tags - backend must return an edge
          const rootField = store.getRoot();
          const viewerField = rootField.getLinkedRecord('viewer');
          const _addToConnection = (connectionName) => {
            const connection = ConnectionHandler.getConnection(
              viewerField,
              connectionName,
            );
            if (connection) {
              tagsEdges.forEach((newEdge) => {
                ConnectionHandler.insertEdgeBefore(connection, newEdge);
              });
              connection.setValue(
                connection.getValue('totalCount') + tagsEdges.length,
                'totalCount',
              );
            }
          };
          const _deleteFromConnection = (connectionName) => {
            const connection = ConnectionHandler.getConnection(
              viewerField,
              connectionName,
            );
            if (connection) {
              ConnectionHandler.deleteNode(connection, id);
            }
          };

          // if the updated tag is boosted -> add it to the curate list
          if (primaryTagInput.boost) {
            ['TagsViewCurate_tags'].forEach((connectionName) =>
              _addToConnection(connectionName),
            );
          }
          if (primaryTagInput.boost === false) {
            // if the updated tag is boost: false -> remove it from the curate list
            ['TagsViewCurate_tags'].forEach((connectionName) =>
              _deleteFromConnection(connectionName),
            );
          }
        }
      },
      onCompleted: resolve,
      onError: reject,
    });
  });
