/**
 * @generated SignedSource<<4669f2f5ddae0b3c404c7c1cf15b5965>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdatePrimaryTagInput = {|
  block?: ?boolean,
  boost?: ?boolean,
  clientMutationId?: ?string,
  do_not_suggest?: ?boolean,
  emoji?: ?string,
  id: string,
  is_smart?: ?boolean,
  remote?: ?boolean,
  title?: ?string,
|};
export type UpdatePrimaryTagMutation$variables = {|
  input: UpdatePrimaryTagInput,
|};
export type UpdatePrimaryTagMutation$data = {|
  +updatePrimaryTag: ?{|
    +error: ?{|
      +message: ?string,
    |},
    +primaryTag: ?{|
      +block: ?boolean,
      +boost: ?boolean,
      +do_not_suggest: ?boolean,
      +emoji: ?string,
      +id: string,
      +remote: ?boolean,
      +slug: ?string,
      +title: ?string,
      +used_at: ?any,
    |},
    +tagsEdges: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +block: ?boolean,
          +boost: ?boolean,
          +do_not_suggest: ?boolean,
          +emoji: ?string,
          +id: string,
          +remote: ?boolean,
          +slug: ?string,
          +title: ?string,
          +used_at: ?any,
        |},
      |}>,
    |},
  |},
|};
export type UpdatePrimaryTagMutation = {|
  response: UpdatePrimaryTagMutation$data,
  variables: UpdatePrimaryTagMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "slug",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "emoji",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "remote",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "boost",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "do_not_suggest",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "block",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "used_at",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Tag",
  "kind": "LinkedField",
  "name": "primaryTag",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "TagConnection",
  "kind": "LinkedField",
  "name": "tagsEdges",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TagEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Tag",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdatePrimaryTagMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePrimaryTagPayload",
        "kind": "LinkedField",
        "name": "updatePrimaryTag",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdatePrimaryTagMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePrimaryTagPayload",
        "kind": "LinkedField",
        "name": "updatePrimaryTag",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d3d6da1c789398706ea3541350b01447",
    "id": null,
    "metadata": {},
    "name": "UpdatePrimaryTagMutation",
    "operationKind": "mutation",
    "text": "mutation UpdatePrimaryTagMutation(\n  $input: UpdatePrimaryTagInput!\n) {\n  updatePrimaryTag(input: $input) {\n    primaryTag {\n      id\n      title\n      slug\n      emoji\n      remote\n      boost\n      do_not_suggest\n      block\n      used_at\n    }\n    tagsEdges {\n      edges {\n        node {\n          id\n          title\n          slug\n          emoji\n          remote\n          boost\n          do_not_suggest\n          block\n          used_at\n        }\n      }\n    }\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "e24edb4096394dcf2745eedd59279438";

module.exports = ((node/*: any*/)/*: Mutation<
  UpdatePrimaryTagMutation$variables,
  UpdatePrimaryTagMutation$data,
>*/);
