/**
 * @generated SignedSource<<093c038328c8d731ca3bb736cdc75538>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentType } from "relay-runtime";
declare export opaque type PrimaryTag_primaryTag$fragmentType: FragmentType;
export type PrimaryTag_primaryTag$data = {|
  +block?: ?boolean,
  +boost?: ?boolean,
  +categories?: ?$ReadOnlyArray<?{|
    +id: string,
    +title: ?string,
  |}>,
  +corrected_to_tag?: ?{|
    +id: string,
    +title: ?string,
  |},
  +created_at?: any,
  +do_not_suggest?: ?boolean,
  +emoji?: ?string,
  +id: string,
  +remote?: ?boolean,
  +skillTags?: ?$ReadOnlyArray<?{|
    +id: string,
    +title: ?string,
  |}>,
  +slug: ?string,
  +synonyms?: ?$ReadOnlyArray<?{|
    +id: string,
    +title: ?string,
  |}>,
  +tags_with_this_category?: ?$ReadOnlyArray<?{|
    +id: string,
    +title: ?string,
  |}>,
  +title: ?string,
  +used_at?: ?any,
  +$fragmentType: PrimaryTag_primaryTag$fragmentType,
|};
export type PrimaryTag_primaryTag$key = {
  +$data?: PrimaryTag_primaryTag$data,
  +$fragmentSpreads: PrimaryTag_primaryTag$fragmentType,
  ...
};
*/

var node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "isAll"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "isAllOrCategories"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "isAllOrCurate"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "isAllOrCurateOrUsed"
    },
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "isAllOrUsed"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isCategories"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PrimaryTag_primaryTag",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "condition": "isAllOrCategories",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emoji",
          "storageKey": null
        }
      ]
    },
    {
      "condition": "isAllOrCurateOrUsed",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "remote",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "do_not_suggest",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "boost",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "block",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "used_at",
          "storageKey": null
        }
      ]
    },
    {
      "condition": "isAllOrUsed",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Tag",
          "kind": "LinkedField",
          "name": "corrected_to_tag",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ]
    },
    {
      "condition": "isAllOrCurate",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Tag",
          "kind": "LinkedField",
          "name": "categories",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Tag",
          "kind": "LinkedField",
          "name": "synonyms",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Tag",
          "kind": "LinkedField",
          "name": "skillTags",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ]
    },
    {
      "condition": "isCategories",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Tag",
          "kind": "LinkedField",
          "name": "tags_with_this_category",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ]
    },
    {
      "condition": "isAll",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "created_at",
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Tag",
  "abstractKey": null
};
})();

(node/*: any*/).hash = "e90c0fe0969ddf76609635f14f38c8da";

module.exports = ((node/*: any*/)/*: Fragment<
  PrimaryTag_primaryTag$fragmentType,
  PrimaryTag_primaryTag$data,
>*/);
